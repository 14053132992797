import _axios from "axios";
import store from "../store";
export const API_BASE = process.env.VUE_APP_API_BASE;

export const axios = _axios.create({
  baseURL: API_BASE,
  headers: {
    // mode: "no-cors",
    Accept: "application/json",
  },
});

export default () => {
  axios.interceptors.request.use(async (config) => {
    if (store.state.auth.token) {
      config.headers["Authorization"] = "Token " + store.state.auth.token;
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return {
    auth: {
      login: (params) => axios.post("/tagreport/authtoken/", params),
    },
    batchList: {
      get: (params) => axios.get("/tagreport/batch/", { params }),
      retriveBatch: (params) => axios.get(`tagreport/batch/`, { params }),
      downloadCsv: (id) => axios.get(`/tagreport/batch/${id}/csv/`),
      downloadStageCsv: (batch, id) =>
        axios.get(`/tagreport/batch/${batch}/stage/${id}/csv/`),
    },
  };
};
