export default {
  data() {
    return {
      validationRule: {
        required: (val) => !!val || "This field is required",
        number: (val) => !/[0-9]/.test(val) || "Please enter only number",
        ip_address: (val) =>
          /(([0-1]?[0-9]{1,2}\.)|(2[0-4][0-9]\.)|(25[0-5]\.)){3}(([0-1]?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))/.test(
            val
          ) || "Pleae enter valid IP address",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      passwordRules: [
        (val) => !!val || "Password is required",
        (val) => (val && val.length >= 5) || "Password must have 5 characters",
        (val) => /(?=.*[A-Z])/.test(val) || "Must have one uppercase character",
        (val) => /(?=.*\d)/.test(val) || "Must have one number",
        (val) =>
          /([!@$%])/.test(val) || "Must have one special character [!@#$%]",
      ],
    };
  },
};
