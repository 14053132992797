import Vue from "vue";

const state = {
  batchList: [],
  batch: null,
};

const mutations = {
  SET_BATCH_TABLE: (state, payload) => {
    state.batchList = payload;
  },
  SET_BATCH: (state, payload) => {
    state.batch = payload[0];
  },
};

const actions = {
  fetchBatchList({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.batchList
        .get(params)
        .then(({ data }) => {
          commit("SET_BATCH_TABLE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  retriveBatch({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.batchList
        .retriveBatch(params)
        .then(({ data }) => {
          commit("SET_BATCH", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  downloadCsv(_, batch_id) {
    return new Promise(() => {
      Vue.prototype.$api.batchList
        .downloadCsv(batch_id)
        .then((blob) => {
          var binaryData = [];
          binaryData.push(blob.data);
          var url = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: "application/*",
            })
          );
          var a = document.createElement("a");
          a.href = url;
          a.download = `${batch_id}_file.csv`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); // afterwards we remove the element again
        })
        .catch((err) => {
          throw err;
        });
    });
  },
  downloadProfileCsv(_, { batch, id }) {
    return new Promise(() => {
      Vue.prototype.$api.batchList
        .downloadStageCsv(batch, id)
        .then((blob) => {
          var binaryData = [];
          binaryData.push(blob.data);
          var url = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: "application/*",
            })
          );
          var a = document.createElement("a");
          a.href = url;
          a.download = `${batch}_stage${id}_file.csv`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((err) => {
          throw err;
        });
    });
  },
};

const getters = {
  getBatchList: (state) => state.batchList,
  getBatch: (state) => state.batch,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
