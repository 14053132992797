<template>
  <div :class="rootClasses" :style="{ width: 'max-content' }">
    <div class="d-flex flex-column mx-5" v-if="lot">
      <span :class="[_titleColor, 'text-center font-weight-bold']" v-if="lot">{{
        $tc("label.lot", lot)
      }}</span>
      <span :class="[_titleColor, 'text-center']">{{ lot }}</span>
    </div>
    <div class="d-flex flex-column mx-5" v-if="username">
      <span :class="[_titleColor, 'text-center font-weight-bold']">{{
        $t("label.username")
      }}</span>
      <span :class="[_titleColor, 'text-center']">{{ username }}</span>
    </div>

    <div class="d-flex flex-column mx-5" v-if="startTime">
      <span :class="[_titleColor, 'text-center font-weight-bold']">{{
        $t("label.start-date")
      }}</span>
      <span :class="[_titleColor, 'text-center']">{{ _startDate() }}</span>
    </div>

    <div class="d-flex flex-column mx-5" v-if="endTime">
      <span :class="[_titleColor, 'text-center font-weight-bold']">{{
        $t("label.end-date")
      }}</span>
      <span :class="[_titleColor, 'text-center']">{{ _endDate() }}</span>
    </div>

    <div class="d-flex flex-column mx-5" v-if="manufacturer">
      <span :class="[_titleColor, 'text-center font-weight-bold']">
        {{ title }}
      </span>
      <span :class="[_titleColor, 'text-center']">{{ manufacturer }}</span>
    </div>
    <div class="d-flex flex-column mx-5" v-if="reference">
      <span :class="[_titleColor, 'text-center font-weight-bold']">
        {{ title }}
      </span>
      <span :class="[_titleColor, 'text-center']">{{ reference }}</span>
    </div>
    <div class="d-flex flex-column mx-5" v-if="description">
      <span :class="[_titleColor, 'text-center font-weight-bold']">
        {{ title }}
      </span>
      <span :class="[_titleColor, 'text-center']">{{ description }}</span>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils";

export default {
  name: "box-regular",
  props: {
    title: {
      type: String,
    },
    lot: {
      type: Number,
    },
    username: {
      type: String,
    },
    startTime: {
      type: String,
    },
    endTime: {
      type: String,
    },
    description: {
      type: String,
    },
    reference: {
      type: String,
    },
    manufacturer: {
      type: String,
    },
    titleColor: {
      type: [String],
      default: "white",
    },
    valueColor: {
      type: [String],
      default: "white",
    },
  },
  data() {
    return {
      rootClasses: [
        "d-flex",
        "flex-row",
        "align-center",
        "mx-10",
        "space-between",
        "w-100",
      ],
    };
  },
  computed: {
    _titleColor() {
      return this.titleColor + "--text";
    },
    _valueColor() {
      return this.valueColor + "--text";
    },
  },
  methods: {
    _startDate() {
      return formatDate(this.startTime);
    },
    _endDate() {
      return formatDate(this.endTime);
    },
  },
};
</script>

<style></style>
