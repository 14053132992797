import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import adminRoutes from "./modules/admin";
import i18n from "@/i18n";

Vue.use(VueRouter);

const beforeEnter = (_to, _from, next) => {
  if (_to.meta.auth) {
    if (store.state.auth.token) {
      if (_to.name === "batch-profile") {
        _to.meta.title = i18n.tc("batch-profile.title", _to.params.id, {
          lot: _to.params.id,
        });
      }
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/admin",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/_layouts/AdminLayout.vue"
      ),
    beforeEnter: beforeEnter,
    children: adminRoutes,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((_to) => {
  if (_to.name === "batch-profile") {
    _to.meta.title = i18n.tc("batch-profile.title", _to.params.id, {
      lot: _to.params.id,
    });
  }
});
export default router;
