import i18n from "@/i18n";
export default [
  {
    path: "batches",
    name: "batch-list",
    component: () =>
      import(
        /* webpackChunkName: "batch-list" */ "@/views/admin/BatchList.vue"
      ),
    meta: {
      title: i18n.t("batch-list.title"),
      auth: true,
    },
  },
  {
    path: "batches/:id/profile",
    name: "batch-profile",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "batch-profile" */ "@/views/admin/BatchProfile.vue"
      ),
    meta: {
      title: (route) =>
        i18n.tc("batch-profile.title", route.params.id, {
          lot: route.params.id,
        }),
      auth: true,
    },
  },
];
